.Navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: var(--standardPadding) var(--standardPadding) 0 var(--standardPadding);

    .go-back {
        flex: 1;
    }

    .close-wrapper {
        flex: 0;
        margin-left: auto;
    }

    // stylelint-disable-next-line selector-class-pattern
    :global(.leftCenter-icon) {
        width: 1.875em;
        height: 1.875em;
        padding: 0.25em;
        border-radius: 50%;
        background-color: rgb(var(--lighterTextColor));
        color: rgb(var(--background));
        cursor: pointer !important;
    }

    // stylelint-disable-next-line selector-class-pattern
    :global(.xCircle-icon) {
        width: 3em;
        height: 3em;
        vertical-align: super;
        color: rgb(var(--lighterTextColor));
        cursor: pointer !important;
    }
}
