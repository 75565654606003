@use 'mixins';

.SignInOrSignUp {
    z-index: 255;
    display: block;
    position: relative;

    &.subscription-flow {
        z-index: inherit;
        padding: var(--largePadding);

        .container {
            margin-top: var(--largePadding);
        }

        .wrapper {
            margin-bottom: 0;
        }
    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: rgb(var(--invertedBackground));
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 var(--standardPadding) var(--largePadding) var(--standardPadding);
        max-width: 27.75em;
        margin: 0 auto;
        margin-bottom: var(--hugePadding);
        border-radius: var(--standardPadding);
        background-color: rgb(var(--background));

        &.first-load-animation {
            @include mixins.slide-in-from-top;
        }
    }

    .container {
        width: 21.375em;
    }

    h1 {
        font-size: 1.8em;
        text-align: center;
    }

    h4 {
        margin: var(--standardPadding) 0 var(--smallPadding) 0;
        font-size: var(--heading-small-font-size);
    }

    .intro {
        padding-bottom: var(--standardPadding);
        font-size: 1em;
        line-height: var(--caption-small-line-height);
        text-align: center;
    }

    .form-error {
        padding: var(--standardPadding);
        margin-bottom: var(--largePadding);
        border-radius: var(--standardRadius);
        background-color: rgba(var(--negativeColor), 0.1);
        color: rgb(var(--errorColor));
        line-height: var(--caption-small-line-height);
    }

    .error {
        color: rgb(var(--errorColor));
    }

    .outro {
        padding: var(--standardPadding);
        margin-top: var(--standardPadding);
        text-align: center;
        line-height: 1.5em;
        border-radius: var(--standardRadius);
        background-color: rgb(var(--sectionBack));
    }

    form {
        padding: var(--tinyPadding);
        background-color: inherit;

        .hide-password-field {
            display: block;
            width: 0;
            height: 0;
            opacity: 0;
        }

        .show-password-field {
            opacity: 1;
            width: inherit;
            height: inherit;
        }

        .submit-button {
            :hover {
                background-color: rgb(var(--mainColorLighter));
                color: rgb(var(--background));
            }

            :disabled {
                background-color: rgb(var(--thirdColor)) !important;
                opacity: 0.2;

                :global(.theme-vi) & {
                    border: none;
                }
            }

            input {
                border: none;
                height: 2.1em;

                :global(.theme-vi) & {
                    height: 2.5em;
                }
            }
        }

        .input-field {
            position: relative;
            margin-bottom: var(--tinyPadding);

            label {
                background-color: rgb(var(--background));
            }

            &.errorField input {
                border: 1px solid rgb(var(--errorColor));
            }

            &.errorField label {
                color: rgb(var(--errorColor));
            }
        }

        .checkbox-field {
            display: inline-block;

            :global(.checkbox-type) {
                width: auto;
            }

            a {
                @extend %link-medium;

                font-size: 0.875em;
                margin-left: var(--tinyPadding);
            }
        }

        span label {
            display: flex;
            align-items: center;
            position: absolute;
            right: 0.5em;
            transform: translateY(-167%);
            cursor: pointer;
        }

        :global(.show-icon) {
            width: var(--largePadding);
        }

        :global(.hide-icon) {
            width: var(--largePadding);
        }

        .show-password {
            display: none;
        }
    }

    .optin {
        padding-top: 0;

        .submit-button input {
            border: solid;
        }
    }

    .forgot-password {
        margin-top: var(--standardPadding);

        .submit-button {
            margin-top: var(--standardPadding);
        }
    }

    .privacy {
        @extend %paragraph-medium;

        padding: 0;
        font-size: 0.75em;

        .link {
            @extend %paragraph-medium;

            text-decoration: underline;
        }
    }

    .marquee {
        display: inline-flex;
        align-items: center;
        padding: 0.25em 0;
        gap: 1.5em;

        .logo svg {
            height: 1.65em;
            width: auto;
        }
    }

    .seperator {
        @extend %heading-x-small;

        position: relative;
        margin: var(--smallPadding) 0 var(--standardPadding) 0;
        text-align: center;
        font-size: 0.75em;
        color: rgb(var(--lighterTextColor));

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0.5em;
            height: 1px;
            width: 100%;
            background: rgb(var(--sectionBack));
        }

        span {
            position: relative;
            z-index: 0;

            &::before {
                content: '';
                position: absolute;
                inset: 0 calc(var(--standardPadding) * -1) 0 calc(var(--standardPadding) * -1);
                background: rgb(var(--background));
                z-index: -1;
            }
        }
    }

    .titles {
        margin: var(--largePadding) 0 var(--standardPadding) 0;
        text-align: center;
    }

    @include mixins.responsive(m, below) {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        &.subscription-flow {
            z-index: inherit;
            padding: 0;
            min-height: auto;

            .container {
                margin-top: var(--largePadding);
            }

            .wrapper {
                margin-bottom: 0;
            }
        }

        &::before {
            background-color: rgb(var(--background));
        }

        .wrapper {
            margin-bottom: 0;
        }
    }
}
