@use 'mixins';

.About {
    padding: var(--largePadding) 0 0 0;

    .content {
        text-align: center;
    }

    a {
        @extend %caption-medium;

        color: rgb(var(--lighterTextColor));
        font-size: 1em;
        text-transform: uppercase;
        text-decoration: none;

        &::first-letter {
            text-transform: capitalize;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .is-shown {
        display: block;
        width: 100%;
        padding-top: var(--standardPadding);
        color: rgb(var(--secondaryTextColor));
        background-color: rgb(var(--sectionBack));

        :global(.theme-vi) & {
            background-color: rgb(var(--sectionBack));
            color: rgb(var(--secondaryTextColor));
        }
    }

    .default {
        display: none;
    }

    .load-more {
        background-color: rgb(var(--sectionBack));

        p {
            @extend %paragraph-medium;

            padding: var(--standardPadding);
        }
    }
}
