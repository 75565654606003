.ValidationError {
    padding: 0 var(--standardPadding);
    border-radius: var(--standardRadius);
    background-color: rgba(var(--negativeColor), 0.1);

    &.extraPadding {
        margin-top: var(--largePadding);
    }

    p {
        padding: var(--standardPadding) 0;
        color: rgb(var(--errorColor));
    }
}
