.AutoComplete {
    width: 100%;
    display: inline-block;

    aside {
        display: inline-block;
    }

    .header {
        margin-bottom: var(--tinyPadding);
        border: 1px solid rgb(var(--lightSectionBorder));
        border-radius: var(--standardRadius);

        li {
            display: inline-block;
            margin: var(--tinyPadding);
            padding: var(--smallPadding);
            white-space: pre;
            background-color: #ecf6fd;
            color: #51a5f1;
            border-radius: var(--standardRadius);

            // Depending on what content we would like to show here, we might need to add max width and height here.
            > aside {
                vertical-align: middle;
            }
        }

        .icon-container {
            position: relative;
            left: -(var(--tinyPadding));

            :global(.Icon) {
                font-size: 1.25em;
                text-align: center;
                color: #51a5f1;
            }
        }
    }

    .options {
        border: 1px solid rgb(var(--lightSectionBorder));

        li {
            border-bottom: 1px solid rgb(var(--lightSectionBorder));
            margin: var(--tinyPadding);
            padding: var(--tinyPadding);
            color: rgb(var(--textColor));
            cursor: pointer;
        }

        li:last-child {
            border-bottom: none;
        }

        li:hover {
            background-color: rgb(var(--lighterTextColor));
            color: rgb(var(--invertedTextColor));
        }
    }

    :global(.stretch) {
        display: flex;

        > div {
            width: 100%;
        }
    }
}
